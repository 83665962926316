import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import LinkedInLogo from "./../../assets/linkedIn_logo.svg";
import ElevateModal from "../ElevateModal";
import ElevateButton from "../ElevateButton";
import { StrapiTeamMember } from "../../../graphql-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

interface TeamMemberCardProps
  extends Pick<
  StrapiTeamMember,
  "first" | "last" | "title" | "location" | "bio" | "profilePicture" | "largeProfilePicture" | "linkedIn" | "preview"
  > { }
const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  first,
  last,
  title,
  location,
  bio,
  linkedIn,
  largeProfilePicture,
  profilePicture
}) => {
  const [showModal, setShowModal] = useState(false);
  const image = getImage(profilePicture!.localFile! as any); // force cast
  const largeImage = getImage(largeProfilePicture!.localFile! as any); // force cast

  return (
    <>
      <div className="flex flex-col">
        <div className="flex">
          <div>
            <GatsbyImage className="mx-auto mr-6" image={image!} alt={`${first} ${last}`} imgStyle={{ borderRadius: "100%" }} />
          </div>
          <div className="flex flex-col justify-center">
            <div className="text-base font-sans font-semibold">{first} {last}</div>
            <div className="font-sans text-gray-dark">{title}, {location}</div>
          </div>
          <div className="grow"></div>
        </div>
        <p className="my-7 text-sm font-sans text-gray-dark bio">{bio.data.childMarkdownRemark.rawMarkdownBody}</p>
        <div className="grow h-0"></div>
        <div className="flex">
          <div className="font-sans font-bold hover:cursor-pointer" onClick={() => setShowModal(true)}>Read bio</div>
          <div className="grow"></div>
          <a href={linkedIn!} target="_blank" style={(linkedIn && linkedIn.length) ? {} : { display: "none" }}>
            <img
              alt={"LinkedIn"}
              src={LinkedInLogo}
              className="w-1/8 m-auto hover:cursor-pointer grayscale"
            />
          </a>
        </div>
      </div>

      <ElevateModal visible={showModal} onClose={() => setShowModal(false)}>
        <div className="flex flex-col">
          <div className="flex">
            <div>
              <GatsbyImage className="mx-auto mr-6" image={image!} alt={`${first} ${last}`} imgStyle={{ borderRadius: "100%" }} />
            </div>
            <div className="flex flex-col justify-center">
              <div className="text-base font-sans font-semibold">{first} {last}</div>
              <div className="font-sans text-gray-dark">{title}, {location}</div>
            </div>
            <div className="grow"></div>
          </div>
          <p className="text-sm font-sans text-gray-dark whitespace-normal my-7">{bio.data.childMarkdownRemark.rawMarkdownBody}</p>
        </div>
      </ElevateModal>
    </>
  )
};

export default TeamMemberCard;
