import React from "react";

interface ElevateModalProps {
  visible: boolean;
  onClose: () => void;
  noPadding?: boolean;
  noIcon?: boolean;
  sizing?: string
  center?: boolean
  children: JSX.Element
}

const ElevateModal: React.FC<ElevateModalProps> = ({
  visible,
  onClose,
  children,
  noPadding = false,
  noIcon = false,
  sizing = "w-full h-full md:h-auto md:w-5/6 md:mx-auto md:mt-20 lg:w-3/5 2xl:w-1/2",
  center = false
}) => {
  return (
    <div
      className={
        `z-50 fixed inset-0 bg-white bg-opacity-70 h-full w-full ${center ? "text-center": "text-left"}`
      }
      onClick={onClose}
      style={{
        visibility: visible ? "visible" : "hidden",
        transition: "visibility 0.2s ease-in-out, opacity 0.2s ease-in-out",
        opacity: visible ? "1" : "0",
      }}
    >
      <div
        className={`relative shadow-2xl bg-white rounded-3xl ${sizing} ${
          noPadding ? "" : "px-10 py-5"
        }`}
      >
        {noIcon ? null : (
          <button
            onClick={onClose}
            className="absolute right-0 top-0 w-10 h-10 text-orange-light text-lg"
          >
            &times;
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default ElevateModal;
