import React from "react";
import Footer from "../components/Footer";
import SectionHeader from "../components/SectionHeader";
import { Helmet } from "react-helmet";

import "./../styles/index.scss";
import { graphql, useStaticQuery } from "gatsby";
import TeamMemberCard from "../components/TeamMemberCard";
import MenuBar from "../components/MenuBar";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import HeroBanner from "../components/HeroBanner";
import PageContent from "../components/PageContent";
import { TeamPageQuery } from "../../graphql-types";
import { mustBeDefined } from "../utils";
import OrangeLine from "../assets/orange_line_artwork.svg";

const TeamPage: React.FC = () => {
  const queryResults = useStaticQuery<TeamPageQuery>(query);

  // Sanity checks
  const sections = mustBeDefined(queryResults.sections).otherwise(
    "CMS_DATA_MISSING",
    "TeamPageQuery.sections"
  );
  const teamBannerImageDetails = mustBeDefined(
    queryResults.teamBanner
  ).otherwise("IMAGE_DATA_MISSING", "TeamPageQuery.teamBanner");
  const teamBannerImage = getImage(teamBannerImageDetails as ImageDataLike);

  const investmentTeamDetails = queryResults.investmentTeam.edges;
  const businessTeamDetails = queryResults.businessTeam.edges;
  const dseTeamDetails = queryResults.dseTeam.edges;

  const investmentTeam = investmentTeamDetails.map((edge) => edge.node);
  const businessTeam = businessTeamDetails.map((edge) => edge.node);
  const dseTeam = dseTeamDetails.map((edge) => edge.node);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="BAM Elevate Investment Team and Operations Team" />
        <title>BAM Elevate - Team</title>
      </Helmet>
      <main className="w-screen bg-off-white">
        <MenuBar />

        <HeroBanner image={teamBannerImage!} title={sections.heroTitle} description={sections.heroDescription} objectPositionMobile="20%" objectPosition="center" />

        <PageContent>
          <SectionHeader
            centered
            title={sections.investmentTeamTitle}
          />
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-20 lg:gap-24 2xl:gap-32">
              {investmentTeam.map((member) => (
                <TeamMemberCard
                  key={member.id}
                  {...member}
                  largeProfilePicture={member.largeProfilePicture as any}
                  profilePicture={member.profilePicture as any}
                />
              ))}
            </div>
          </div>
          <div className="my-28">
            <img src={OrangeLine} alt=""></img>
          </div>
          <SectionHeader
            centered
            title={sections.portfolioOpsTeamTitle}
          />
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-20 lg:gap-24 2xl:gap-32">
              {businessTeam.map((member) => (
                <TeamMemberCard
                  key={member.id}
                  {...member}
                  largeProfilePicture={member.largeProfilePicture as any}
                  profilePicture={member.profilePicture as any}
                />
              ))}
            </div>
          </div>
          {/* <div className="my-28">
            <img src={OrangeLine} alt=""></img>
          </div>
          <SectionHeader
            centered
            title={sections.dseTeamTitle}
          />
          <div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-20 lg:gap-24 2xl:gap-32">
              {dseTeam.map((member) => (
                <TeamMemberCard
                  key={member.id}
                  {...member}
                  largeProfilePicture={member.largeProfilePicture as any}
                  profilePicture={member.profilePicture as any}
                />
              ))}
            </div> */}
          {/* </div> */}
          </PageContent>
          <PageContent>
            <Footer />
          </PageContent>
      </main>
    </>
  );
};

export default TeamPage;

export const query = graphql`
  query TeamPage {
    sections: strapiTeamPage {
      heroTitle
      heroDescription
      investmentTeamTitle
      portfolioOpsTeamTitle
      dseTeamTitle
    }
    investmentTeam: allStrapiTeamMember(
      sort: {fields: pageOrder}
      filter: { teamName: { eq: "investment" } }
    ) {
      edges {
        node {
          id
          first
          last
          title
          location
          linkedIn
          bio {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          pageOrder
          largeProfilePicture:profilePicture {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 330, quality: 90, layout: FIXED, transformOptions: {cropFocus: ATTENTION})
              }
            }
          }
          profilePicture {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, height: 150, quality: 90, layout: FIXED, transformOptions: {cropFocus: ATTENTION})
              }
            }
          }
        }
      }
    }
    businessTeam: allStrapiTeamMember(
      sort: {fields: pageOrder}
      filter: { teamName: { eq: "business" } }
    ) {
      edges {
        node {
          id
          first
          last
          title
          location
          linkedIn
          bio {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          largeProfilePicture:profilePicture {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 330, quality: 90, layout: FIXED, transformOptions: {cropFocus: ATTENTION})
              }
            }
          }
          profilePicture {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, height: 150, quality: 90, layout: FIXED, transformOptions: {cropFocus: ATTENTION})
              }
            }
          }
          pageOrder
        }
      }
    }
    dseTeam: allStrapiTeamMember(
      sort: {fields: pageOrder}
      filter: { teamName: { eq: "dse" } }
    ) {
      edges {
        node {
          id
          first
          last
          title
          location
          linkedIn
          bio {
            data {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          pageOrder
          largeProfilePicture:profilePicture {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 330, quality: 90, layout: FIXED, transformOptions: {cropFocus: ATTENTION})
              }
            }
          }
          profilePicture {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 150, height: 150, quality: 90, layout: FIXED, transformOptions: {cropFocus: ATTENTION})
              }
            }
          }
        }
      }
    }
    teamBanner: file(
      relativePath: { eq: "header_images/img_team_page_main.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`;
